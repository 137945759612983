import {
  getUserInfo,
  getUserInfo_getUserInfo,
} from "./graphql/__generated__/getUserInfo";

export interface getUserInfoType extends getUserInfo_getUserInfo {
  checked: boolean;
}

export enum TypeInputNameEnum {
  REASON = "REASON",
  AMOUNT = "AMOUNT",
}

export type ClientDataProps = {
  clientData: getUserInfo | undefined
};