import { SelectChangeEvent } from "@mui/material";
import { ReferralProgramStatus } from "../../../../globalTypes";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_AFFILIATE_REFERRAL_PARTICIPATION } from "../../graphql/UPDATE_AFFILIATE_REFERRAL_PARTICIPATION";
import {
  updateAffiliateReferralParticipation,
  updateAffiliateReferralParticipationVariables,
} from "../../graphql/__generated__/updateAffiliateReferralParticipation";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { getUserInfo, } from "../../graphql/__generated__/getUserInfo";
import { GET_USER } from "../../graphql/GET_USER.graphql";

export const useParticipationStatus = (clientData: getUserInfo | undefined) => {

  const [clientReferralProgramStatus, setClientReferralProgramStatus] = useState<ReferralProgramStatus>(ReferralProgramStatus.active);
  const { setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);

  useEffect(() => {
    if (clientData) {
      setClientReferralProgramStatus(clientData?.getUserReferralStatus === "active" ? ReferralProgramStatus.active : ReferralProgramStatus.blocked)
    }

  }, [clientData])

  const handleChange = (event: SelectChangeEvent) => {
    setClientReferralProgramStatus(event.target.value as ReferralProgramStatus);
  };

  const [updateReferalParticipationStatus, { data: dataParticipationData, loading: loadingParticipation, error: errorParticipationData }] =
    useMutation<updateAffiliateReferralParticipation, updateAffiliateReferralParticipationVariables>(UPDATE_AFFILIATE_REFERRAL_PARTICIPATION);

  useEffect(() => {
    if (dataParticipationData && !loadingParticipation && !errorParticipationData) {
      setNotificationEvent(notificationEventType.success)
      setNotificationMsg("Статус успешно изменен!");
    }
  }, [dataParticipationData, errorParticipationData, loadingParticipation, setNotificationEvent, setNotificationMsg])

  const updateReferalParticipationStatusHandler = () => {
    if (!clientData) {
      return
    }
    updateReferalParticipationStatus({
      variables: {
        userID: clientData.getUserInfo.user.id,
        status: clientReferralProgramStatus,
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: { userID: clientData?.getUserInfo.user.id },
        },
      ],
    });
  };

  return {
    handleChange,
    clientReferralProgramStatus,
    updateReferalParticipationStatusHandler,
    loadingParticipation,
  };
};
