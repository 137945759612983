import React, { Fragment } from "react";
import ClientSearchPanel from "./components/clientSearchPanel/ClientSearchPanel";
import { useClient } from "./useClient";
import { Box, Typography } from "@mui/material";
import { HeaderTitle } from "../../styles/common/styles";
import { grey } from "@mui/material/colors";
import ClientCard from "./components/clientCard/CientCard";

const Clients = () => {
  const {
    searchTextId,
    setSearchTextId,
    getUserHandler,
    clientData
  } = useClient();

  return (
    <>
      <HeaderTitle>Клиенты</HeaderTitle>
      <ClientSearchPanel
        searchTextId={searchTextId}
        setSearchTextId={setSearchTextId}
        getUserHandler={getUserHandler}
      />

      <Box>
        {clientData?.getUserInfo ? (
          <ClientCard clientData={clientData} />
        ) : (
          <Typography color={grey[400]}>Нам нужно найти клиента по ID</Typography>
        )}
      </Box>
    </>
  );
};

export default Clients;
