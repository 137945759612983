import React from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { UserCategory } from '../../../../globalTypes'
import { useChangeClientCategory } from './useChangeClientCategory'
import { ClientDataProps } from '../../types'

const ChangeClientCategory = ({ clientData }: ClientDataProps) => {
    const { category, handleChange, setClientCategory } = useChangeClientCategory(clientData)
    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Box sx={{ minWidth: "60%" }}>
                <FormControl fullWidth sx={{ height: "40px" }}>
                    <InputLabel id="demo-select-small-label">
                        Категория клиента
                    </InputLabel>
                    <Select
                        fullWidth 
                        sx={{ height: "40px" }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={category}
                        label="Категория клиента"
                        onChange={handleChange}
                    >
                        <MenuItem value={UserCategory.regular}>
                            {UserCategory.regular}
                        </MenuItem>
                        <MenuItem value={UserCategory.pre_vip}>
                            {UserCategory.pre_vip}
                        </MenuItem>
                        <MenuItem value={UserCategory.vip}>
                            {UserCategory.vip}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Button onClick={setClientCategory} variant='contained'>Сохранить</Button>
        </Box>
    )
}

export default ChangeClientCategory
