import React from "react";
import { Box, Button } from "@mui/material";
import { acceptOnlyNumbers } from "../../../../utils/numbersUtils";
import { StyledOutlinedInput } from "../../styles";
import { ClientDataProps, TypeInputNameEnum } from "../../types";
import { filteredText } from "../../utils";
import { useBalanceClientContentModal } from "./useBalanceClientContentModal";
import Loader from "../../../common/loader";

const BalanceClientContentModal = ({ clientData }: ClientDataProps) => {
  const {
    setAmountBalanceClientHandler,
    balanceClient,
    setBalanceClient,
    reasonBalanceClient,
    setReasonBalanceClient,
    controlBalanceLoading,
  } = useBalanceClientContentModal(clientData);

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: TypeInputNameEnum
  ) => {
    const textInput = event.target.value;

    if (type === TypeInputNameEnum.REASON) {
      setReasonBalanceClient(filteredText(textInput));
    } else {
      setBalanceClient(textInput);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 2, width: "60%" }}>
        <StyledOutlinedInput
          fullWidth
          placeholder="Опишите причину"
          onChange={(e) => changeHandler(e, TypeInputNameEnum.REASON)}
          value={reasonBalanceClient}
        />
        <StyledOutlinedInput
          fullWidth
          placeholder="Количество рублей"
          onChange={(e) => changeHandler(e, TypeInputNameEnum.AMOUNT)}
          value={`${balanceClient}`}
          onInput={acceptOnlyNumbers}
        />
      </Box>
      <Button
        disabled={!balanceClient || !reasonBalanceClient}
        variant="contained"
        onClick={setAmountBalanceClientHandler}
      >
        Сохранить
        {controlBalanceLoading && <Loader />}
      </Button>
    </Box>
  );
};

export default BalanceClientContentModal;
