import React from "react";
import { Box, Button } from "@mui/material";
import { StyledOutlinedInput } from "../../styles";
import { ClientDataProps, TypeInputNameEnum } from "../../types";
import { acceptOnlyNumbers } from "../../../../utils/numbersUtils";
import { useFreeMinutesContentModal } from "./useFreeMinutesContentModal";

const FreeMinutesContentModal = ({ clientData }: ClientDataProps) => {
  const {
    setFreeMinutersHandler,
    changeHandler,
    freeMinutesAmount,
    reasonForFreeMinutes
  } = useFreeMinutesContentModal(clientData)

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 2, width: "60%" }}>
          <StyledOutlinedInput
            fullWidth
            placeholder="Опишите причину"
            onChange={(e) => changeHandler(e, TypeInputNameEnum.REASON)}
            value={reasonForFreeMinutes}
          />
          <StyledOutlinedInput
            fullWidth
            placeholder="Количество минут"
            onChange={(e) => changeHandler(e, TypeInputNameEnum.AMOUNT)}
            value={freeMinutesAmount}
            onInput={acceptOnlyNumbers}
          />
        </Box>
        <Button
          disabled={!(reasonForFreeMinutes && freeMinutesAmount)}
          variant="contained"
          onClick={setFreeMinutersHandler}
        >
          Сохранить
        </Button>
      </Box>
    </>
  );
};

export default FreeMinutesContentModal;
