import React from 'react'
import { Avatar, Box, Paper, Typography } from '@mui/material'
import { StyledDivider } from '../../../../styles/common/styles'
import { HeaderTitle } from '../../../schedule/allSchedules/styles'
import BalanceClientContentModal from '../balanceClientContentModal'
import BlockUserContentModal from '../blockUserContentModal'
import ChangeClientCategory from '../changeClientCategory'
import DeleteClientAccount from '../deleteClientAccount'
import FreeMinutesContentModal from '../freeMinutesContentModal'
import ParticipationStatusBlock from '../participationStatus'
import { ClientDataProps } from '../../types'

const ClientCard = ({ clientData }: ClientDataProps) => {
    const userData = clientData?.getUserInfo

    return (
        <Paper elevation={24} sx={{ padding: 3, borderRadius: 7 }}>
            <Box display={"flex"} justifyContent={"space-between"} >
                <HeaderTitle>Редактировать данные клиента</HeaderTitle>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "max-content",
                    alignItems: "center"
                }}>
                    <Avatar src={clientData?.getUserInfo.user.image.onex} variant='rounded' sx={{ width: 40, height: 40 }} />
                    <Typography>ID: {userData?.user.id}</Typography>
                    <Typography>{clientData?.getUserInfo.user.name}</Typography>
                </Box>
            </Box>

            <Box display={"flex"} gap={10}>
                <Box sx={{ width: "60%" }}>
                    <StyledDivider textAlign="left">Добавляем или снимаем бесплатные минуты</StyledDivider>
                    <FreeMinutesContentModal clientData={clientData} />

                    <StyledDivider textAlign="left">Баланс</StyledDivider>
                    <BalanceClientContentModal clientData={clientData} />

                    <StyledDivider textAlign="left">Редактирование статуса для аффилиата</StyledDivider>
                    <ParticipationStatusBlock clientData={clientData} />

                    <StyledDivider textAlign="left">Категория клиента</StyledDivider>
                    <ChangeClientCategory clientData={clientData} />

                    <StyledDivider textAlign="left">Блокировка / Разблокировка клиентов</StyledDivider>
                    <BlockUserContentModal clientData={clientData} />

                    <StyledDivider textColor="red" textAlign="left">Удалить аккаунт</StyledDivider>
                    <DeleteClientAccount clientData={clientData} />
                </Box>

                <Box sx={{ width: "40%", }}>
                    <StyledDivider textAlign="left">Данные на сайте</StyledDivider>
                    <Box>
                        <Typography>Бесплатные минуты {clientData?.getUserInfo.user.freeMinutesLeft} мин.</Typography>
                        <Typography>Баланс {clientData?.getUserInfo.balance.amount} руб.</Typography>
                        <Typography>Статуса для аффилиата "{clientData?.getUserReferralStatus}"</Typography>
                        <Typography>Категория клиента "{clientData?.getUserInfo.category}"</Typography>
                        <Typography>Блокировка / Разблокировка "{clientData?.getUserInfo.blocked ? "Неактивный" : "Активный"}"</Typography>
                        <Typography>Тип входа - "{userData?.user.loginType}"</Typography>
                        <Typography>Сессия - {userData?.HasActiveSession ? "Да" : "Нет"}</Typography>
                        <Typography>Реферальная программа -{" "}{clientData?.getUserReferralStatus || ""}</Typography>
                        <Typography>Клиент - {userData?.blocked ? "Заблокирован" : "Разблокирован"}</Typography>
                    </Box>
                </Box>
            </Box>


        </Paper>
    )
}

export default ClientCard
