import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { addFreeMinutes, addFreeMinutesVariables } from "../../graphql/__generated__/addFreeMinutes";
import { ADD_FREE_MINUTES } from "../../graphql/ADD_FREE_MINUTES.graphql";
import { GET_USER } from "../../graphql/GET_USER.graphql";
import { TypeInputNameEnum } from "../../types";
import { filteredText } from "../../utils";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { getUserInfo } from "../../graphql/__generated__/getUserInfo";

export const useFreeMinutesContentModal = (clientData: getUserInfo | undefined) => {
    const [freeMinutesAmount, setFreeMinutesAmount] = useState("");
    const [reasonForFreeMinutes, setReasonForFreeMinutes] = useState("");
    const { setLoading, setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);

    const [addFreeMinutes, { data: addFreeMinutesData, loading: addFreeMinutesLoading, error: addFreeMinutesError },
    ] = useMutation<addFreeMinutes, addFreeMinutesVariables>(ADD_FREE_MINUTES);

    const changeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        type: TypeInputNameEnum
    ) => {
        const inputText = event.target.value;
        if (type === TypeInputNameEnum.REASON) {
            setReasonForFreeMinutes(filteredText(inputText));
        } else {
            setFreeMinutesAmount(inputText);
        }
    };


    const setFreeMinutersHandler = () => {
        if (!clientData) {
            return
        }

        addFreeMinutes({
            variables: {
                input: {
                    Amount: Number(freeMinutesAmount),
                    Reason: reasonForFreeMinutes,
                    userIDs: [clientData?.getUserInfo.user.id]
                }
            },
            refetchQueries: [
                {
                    query: GET_USER,
                    variables: { userID: clientData?.getUserInfo.user.id },
                },
            ],
        })
    };

    useEffect(() => {
        const loading = addFreeMinutesLoading;
        setLoading(loading);

        switch (true) {
            case Boolean(addFreeMinutesError):
                setNotificationEvent(notificationEventType.error);
                setNotificationMsg("Ошибка: баланс БМ нельзя увести ниже нуля");
                break;
            case Boolean(addFreeMinutesData):
                setNotificationEvent(notificationEventType.success);
                setNotificationMsg("Успех");
                setReasonForFreeMinutes("");
                setFreeMinutesAmount("");
                break;
            default:
                break;
        }
    }, [addFreeMinutesLoading, addFreeMinutesData, setLoading, addFreeMinutesError, setNotificationEvent, setNotificationMsg]);

    return {
        setFreeMinutersHandler,
        setReasonForFreeMinutes,
        setFreeMinutesAmount,
        changeHandler,
        freeMinutesAmount,
        reasonForFreeMinutes
    }
}