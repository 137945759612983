import { gql } from "@apollo/client";

export const GET_USER = gql`
 query getUserInfo($userID: Int!) {
    getUserInfo(input: {UserID: $userID}) {
      user {
        id
        uuid
        name
        freeMinutesLeft
        loginType
        image(size: 100) {
          onex
          twox
          threex
        }
        isDeleted
      }
      balance {
        ... on Money {
          amount
          currency
        }
      }
      HasActiveSession
      blocked
      category
    }
    getUserReferralStatus(userID: $userID)
  }
`;
