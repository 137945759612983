import { gql } from "@apollo/client";

export const GET_EXPERT_FOR_UPDATE = gql`
  query getExpertForUpdate($id: Int!) {
    getExpert(id: $id) {
      id
      uuid
      name
      description
      image(size: 100) {
        onex
      }
      cover {
        horizontal1x
        horizontal2x
        vertical1x
        vertical2x
        vertical3x
      }
      audioGreetingUrl
      rates {
        chat
        call
        videoCall
        phoneCall
      }
      gender
      userID
      rating
      skills {
        id
        name
        badge
        description
        expertsCount
      }
      methods {
        id
        name
        description
        expertsCount
      }
      totalSessionsCount
      careerStartDate
      experienceInYears
      title
      state
      reviewsCount
      scopes {
        id
        name
        description
        scopesCount
      }
      tg
      trialAllowance {
        video
        chat
        phone
      }
      minSessionDuration
      displayStatus
      activityStatus
      catalogPriority
    }
  }
`;
