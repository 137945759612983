import { useContext, useEffect, useState } from "react"
import { UserCategory } from "../../../../globalTypes"
import { SelectChangeEvent } from "@mui/material";
import { useMutation } from "@apollo/client";
import { CHANGE_CATEGORY } from "../../graphql/CHANGE_CATEGORY.graphql";
import { setUserCategory, setUserCategoryVariables } from "../../graphql/__generated__/setUserCategory";
import { GET_USER } from "../../graphql/GET_USER.graphql";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { getUserInfo } from "../../graphql/__generated__/getUserInfo";

export const useChangeClientCategory = (clientData: getUserInfo | undefined) => {
    const [category, setCategory] = useState<UserCategory>(clientData?.getUserInfo.category || UserCategory.regular)
    const [setClientCategoryMutation, { data, loading, error }] = useMutation<setUserCategory, setUserCategoryVariables>(CHANGE_CATEGORY)
    const { setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);


    const handleChange = (event: SelectChangeEvent) => {
        setCategory(event.target.value as UserCategory);
    };

    const setClientCategory = () => {
        if (clientData?.getUserInfo) {
            setClientCategoryMutation({
                variables: {
                    category,
                    userID: clientData?.getUserInfo.user.id
                },
                refetchQueries: [
                    {
                        query: GET_USER,
                        variables: { userID: clientData?.getUserInfo.user.id },
                    },
                ],
            })
        }
    }

    useEffect(() => {
        if (data && !loading && !error) {
            setNotificationEvent(notificationEventType.success);
            setNotificationMsg("Категория успешно изменена");
        }
    }, [data, error, loading, setNotificationEvent, setNotificationMsg])

    return {
        category,
        handleChange,
        setClientCategory
    }
}