import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { GET_BLOCKED_USERS } from "../../graphql/GET_BLOCKED_USERS.graphql";
import { getBlockedUsers, getBlockedUsersVariables, } from "../../graphql/__generated__/getBlockedUsers";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { BLOCK_USER } from "../../graphql/BLOCK_USER.graphql";
import { blockUserByAdmin, blockUserByAdminVariables, } from "../../graphql/__generated__/blockUserByAdmin";
import { unBlockUserByAdmin, unBlockUserByAdminVariables, } from "../../graphql/__generated__/unBlockUserByAdmin";
import { UN_BLOCK_USER } from "../../graphql/UN-BLOCK-USER.graphql";
import { addCurrentDateYears } from "../../../../utils/useDate";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { getUserInfo } from "../../graphql/__generated__/getUserInfo";
import { GET_USER } from "../../graphql/GET_USER.graphql";

export const useBlockUserContentModal = (clientData: getUserInfo | undefined) => {
  const [reasonBlockClient, setReasonBlockClient] = useState("");
  const [blockUntilDateClient, setBlockUntilDateClient] = useState<string>("");
  const [isBlockForever, setIsBlockForever] = useState(false);
  const { setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);

  const [getBlockedUsers, { data: blockedUsersData }] = useLazyQuery<getBlockedUsers, getBlockedUsersVariables>(GET_BLOCKED_USERS);
  const [blockUser, { data: blockUserData }] = useMutation<blockUserByAdmin, blockUserByAdminVariables>(BLOCK_USER);
  const [unBlockUser, { data: usBlockUserData }] = useMutation<unBlockUserByAdmin, unBlockUserByAdminVariables>(UN_BLOCK_USER);

  const blockUserHandler = () => {
    if (blockUntilDateClient && reasonBlockClient) {
      blockUser({
        variables: {
          input: {
            blockUntil: blockUntilDateClient,
            reason: reasonBlockClient,
            userID: clientData?.getUserInfo.user.uuid,
          },
        },
        refetchQueries: [
          {
            query: GET_BLOCKED_USERS,
            variables: {
              input: { userID: clientData?.getUserInfo.user.uuid },
            },
          },
          {
            query: GET_USER,
            variables: { getUserInfo: { UserID: clientData?.getUserInfo.user.id } },
          },
        ],
      });
      setBlockUntilDateClient("");
      setReasonBlockClient("");
    } else {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg("Необходимо указать причину и время блокировки");
    }
  };

  const unBlockUserHandler = () => {
    clientData?.getUserInfo &&
      unBlockUser({
        variables: {
          input: clientData?.getUserInfo.user.uuid!,
        },
        refetchQueries: [
          {
            query: GET_BLOCKED_USERS,
            variables: {
              input: { userID: clientData?.getUserInfo.user.uuid },
            },
          },
        ],
      });
    setBlockUntilDateClient("");
    setReasonBlockClient("");
  };

  useEffect(() => {
    if (usBlockUserData || blockUserData) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успех");
    }
  }, [
    blockUserData,
    setNotificationEvent,
    setNotificationMsg,
    usBlockUserData,
  ]);

  useEffect(() => {
    clientData?.getUserInfo &&
      getBlockedUsers({
        variables: { input: { userID: clientData?.getUserInfo.user.uuid } },
      });
  }, [clientData, getBlockedUsers]);

  useEffect(() => {
    isBlockForever ? setBlockUntilDateClient(addCurrentDateYears(300)) : setBlockUntilDateClient("");
  }, [isBlockForever]);

  const currentStatusClientData = useMemo(
    () =>
      blockedUsersData?.getBlockedUsers.find(
        (itemHistory) => itemHistory?.isBlocked === true
      ),
    [blockedUsersData]
  );
  return {
    currentStatusClientData,
    blockUntilDateClient,
    reasonBlockClient,
    unBlockUserHandler,
    blockUserHandler,
    setReasonBlockClient,
    setBlockUntilDateClient,
    setIsBlockForever,
  };
};
