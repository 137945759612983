import React from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField, } from "@mui/material";
import { StyledOutlinedInput } from "../../styles";
import { DatePicker } from "@mui/x-date-pickers-pro";
import DataPickerIcon from "../../../../assets/icons/DataPickerIcon";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { filteredText } from "../../utils";
import { useBlockUserContentModal } from "./useBlockUserContentModal";
import { ClientDataProps } from "../../types";

const BlockUserContentModal = ({ clientData }: ClientDataProps) => {
  const {
    blockUserHandler,
    currentStatusClientData,
    unBlockUserHandler,
    setReasonBlockClient,
    blockUntilDateClient,
    reasonBlockClient,
    setBlockUntilDateClient,
    setIsBlockForever,
  } = useBlockUserContentModal(clientData);
  const tomorrow = dayjs().add(1, "day");

  const shouldDisableDate = (date: any) => {
    return date.isBefore(tomorrow, "day");
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between", maxWidth: "60%" }}>
          <StyledOutlinedInput
            fullWidth
            placeholder="Опишите причину"
            onChange={(event) =>
              setReasonBlockClient(filteredText(event.target.value))
            }
            value={reasonBlockClient}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              components={{
                OpenPickerIcon: DataPickerIcon,
              }}
              inputFormat="DD.MM.YYYY"
              value={blockUntilDateClient || null}
              onChange={(e: Dayjs | null) =>
                setBlockUntilDateClient(dayjs(e).format())
              }
              shouldDisableDate={shouldDisableDate}
              renderInput={(params) => <TextField {...params} size="small" fullWidth />}
            />
          </LocalizationProvider>
        </Box>

        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <FormControlLabel
            label="Навсегда"
            control={
              <Checkbox
                onChange={(event) => setIsBlockForever(event.target.checked)}
              />
            }
          />
          <Button
            variant="contained"
            disabled={!!currentStatusClientData?.isBlocked}
            onClick={blockUserHandler}
            sx={{ minWidth: "unset" }}
          >
            Блокировать
          </Button>
          <Button
            variant="contained"
            disabled={!currentStatusClientData?.isBlocked}
            onClick={unBlockUserHandler}
            sx={{ minWidth: "unset" }}
          >
            Разблокировать
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BlockUserContentModal;
