import React from "react";
import { Button, InputAdornment } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ClientSearchPanelProps } from "./types";

import { StyledOutlinedInput, StyledSearchBlock, StyledSearchPanelWrapper } from "../../../../styles/common/styles";
import IconComponent from "../../../common/icon";

const ClientSearchPanel = ({ setSearchTextId, getUserHandler, searchTextId, }: ClientSearchPanelProps) => {
  const handlerSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTextId(event.target.value);
  };

  return (
    <StyledSearchPanelWrapper>
      <StyledSearchBlock>
        <StyledOutlinedInput
          placeholder="Поиск по ID клиента"
          onChange={handlerSearchText}
          value={searchTextId}
          endAdornment={
            <InputAdornment position="end">
              <IconComponent icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />
        <Button variant="contained" color="info" onClick={getUserHandler}>
          Поиск
        </Button>
      </StyledSearchBlock>
    </StyledSearchPanelWrapper>
  );
};

export default ClientSearchPanel;
