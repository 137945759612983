import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import { ReferralProgramStatus } from "../../../../globalTypes";
import { useParticipationStatus } from "./useParticipationStatus";
import Loader from "../../../common/loader";
import { ClientDataProps } from "../../types";

const ParticipationStatusBlock = ({ clientData }: ClientDataProps) => {
  const {
    clientReferralProgramStatus,
    handleChange,
    updateReferalParticipationStatusHandler,
    loadingParticipation,
  } = useParticipationStatus(clientData);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 2, width: "60%"}} >
        <FormControl fullWidth sx={{ height: "40px" }}>
          <InputLabel id="demo-select-small-label">
            Участие в реферальной программе
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={clientReferralProgramStatus}
            label="Участия в реферальной программе"
            onChange={handleChange}
            sx={{ height: "40px" }}
          >
            <MenuItem value={ReferralProgramStatus.active}>
              {ReferralProgramStatus.active}
            </MenuItem>
            <MenuItem value={ReferralProgramStatus.blocked}>
              {ReferralProgramStatus.blocked}
            </MenuItem>
            <MenuItem value={ReferralProgramStatus.not_available} disabled>
              {ReferralProgramStatus.not_available}
            </MenuItem>
            <MenuItem value={ReferralProgramStatus.unblocked} disabled>
              {ReferralProgramStatus.unblocked}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Button
        variant="contained"
        disabled={loadingParticipation}
        onClick={updateReferalParticipationStatusHandler}
      >
        Сохранить
        {loadingParticipation && <Loader />}
      </Button>
    </Box>
  );
};

export default ParticipationStatusBlock;
