import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { GET_USER } from "./graphql/GET_USER.graphql";
import { getUserInfo, getUserInfoVariables, } from "./graphql/__generated__/getUserInfo";
import { notificationEventType } from "../../contexts/globalContext/types";
import { useKeyboardEvent } from "../../utils/useKeyboard";
import { getErrorByCode, getErrorCode } from "../../utils/errorUtils";

export const useClient = () => {
  const [searchTextId, setSearchTextId] = useState("");
  const [blockUntilDateClient, setBlockUntilDateClient] = useState<string>("");
  const { setLoading, setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);

  const [getUser, { data: userData, loading: userLoading, error: userError }] =
    useLazyQuery<getUserInfo, getUserInfoVariables>(GET_USER, {
      fetchPolicy: "network-only",
    });


  const getUserHandler = () => {
    if (searchTextId.trim() && !Number.isNaN(Number(searchTextId))) {
      getUser({
        variables: {
          userID: Number(searchTextId)
        },
      });
      setSearchTextId("");
    } else {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg("нужно указать ID клиента цифрами");
    }
  };

  useKeyboardEvent("Enter", getUserHandler);

  useEffect(() => {
    const loading = userLoading;
    setLoading(loading);

    switch (true) {
      case Boolean(userError):
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg(getErrorByCode(getErrorCode(userError)) || "");
        break;
      default:
        break;
    }
  }, [
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    userError,
    userLoading,
  ]);

  return {
    searchTextId,
    blockUntilDateClient,
    setBlockUntilDateClient,
    setSearchTextId,
    getUserHandler,

    clientData: userData
  };
};
