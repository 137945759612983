import { Navigate, useRoutes } from "react-router-dom";

import ExpertsPage from "../pages/@experts";
import ReviewsPage from "../pages/@reviews";
import LoginPage from "../pages/@LoginPage";
import DashboardLayout from "../layout/deshboard";
import ExpertScheduler from "../components/schedule";
import AllSchedulesPage from "../pages/@allSchedules";
import ClientsPage from "../pages/@clients";
import PromoCodesPages from "../pages/@promoCodes";
import BonusesPage from "../pages/@bonuses";
import ReportPage from "../pages/@report";
import SessionPage from "../pages/@sessions";
import PaymentPage from "../pages/@payment";
import IpAddressesPage from "../pages/@ip-addresses";
import ButtonsTemplatesPage from "../pages/@buttons-templates";
import CashBackPage from "../pages/@cashBack";

import { SubRouteCreateUpdateExpertPage } from "../pages/@experts/subRoutes";
import { SubRoutCreateUpdateIpToWhiteListPage } from "../pages/@ip-addresses/subRoutes";
import { SubRoutesCreateUpdateButtonTemplatesPage } from "../pages/@buttons-templates/@subRoutes";
import { SubRoutesCreateUpdateCashBackPage } from "../pages/@cashBack/@subRoutes";
import { SubRoutCreateUpdatePromo, SubRoutJoinPromoToEffise, SubRoutPromosList, } from "../pages/@promoCodes/subRoutes";
import { ProductsPage } from "../pages/@products";
import { SubRoutCreateUpdateProductPage } from "../pages/@payment/subRoutes";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={"/login"} replace />, index: true },
        {
          path: "login",
          element: <LoginPage />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to={"/dashboard/experts"} replace />,
          index: true,
        },
        {
          path: "experts",
          children: [
            { path: "", element: <ExpertsPage /> },
            { path: `:expertId`, element: <ExpertScheduler /> },
            { path: "details", element: <SubRouteCreateUpdateExpertPage /> },
            {
              path: "details/:expertId",
              element: <SubRouteCreateUpdateExpertPage />,
            },
          ],
        },
        { path: "reviews", element: <ReviewsPage /> },
        { path: "schedules", element: <AllSchedulesPage /> },
        {
          path: "clients",
          children: [
            { path: "", element: <ClientsPage /> },
          ]
        },
        {
          path: "promocode",
          children: [
            { path: "", element: <PromoCodesPages /> },
            {
              path: "promo-create-update",
              element: <SubRoutCreateUpdatePromo />,
            },
            { path: `:promoId`, element: <SubRoutPromosList /> },
            {
              path: ":promoId/join-promo-to-effise",
              element: <SubRoutJoinPromoToEffise />,
            },
          ],
        },
        { path: "bonus", element: <BonusesPage /> },
        { path: "report", element: <ReportPage /> },
        { path: "session", element: <SessionPage /> },
        { path: "payment", element: <PaymentPage /> },
        {
          path: "ip-addresses",
          children: [
            { path: "", element: <IpAddressesPage /> },
            {
              path: "add-ip",
              element: <SubRoutCreateUpdateIpToWhiteListPage />,
            },
            {
              path: "update-ip",
              element: <SubRoutCreateUpdateIpToWhiteListPage />,
            },
          ],
        },
        {
          path: "cashback",
          children: [
            { path: "", element: <CashBackPage /> },
            { path: "create", element: <SubRoutesCreateUpdateCashBackPage /> },
            { path: "update", element: <SubRoutesCreateUpdateCashBackPage /> },
          ],
        },
        {
          path: "button-templates",
          children: [
            { path: "", element: <ButtonsTemplatesPage /> },
            {
              path: "create",
              element: <SubRoutesCreateUpdateButtonTemplatesPage />,
            },
            {
              path: "update",
              element: <SubRoutesCreateUpdateButtonTemplatesPage />,
            },
          ],
        },
        {
          path: "products",
          children: [
            { path: "", element: <ProductsPage /> },
            { path: "create", element: <SubRoutCreateUpdateProductPage /> },
            { path: "update", element: <SubRoutCreateUpdateProductPage /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <div>not found</div>,
    },
  ]);
}
