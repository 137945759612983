import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";

import { idFiltering } from "./utils";
import globalDataCTX from "../../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../../contexts/globalContext/types";
import {
  ExpertActivityStatus,
  ExpertDisplayStatus,
  Gender,
} from "../../../../../globalTypes";
import { CREATE_EXPERT } from "../../../graphql/CREATE_EXPERTS.graphql";
import { UPDATE_EXPERT } from "../../../graphql/UPDATE_EXPERT.graphql";
import {
  addExpert,
  addExpertVariables,
} from "../../../graphql/__generated__/addExpert";
import {
  updateExpert,
  updateExpertVariables,
} from "../../../graphql/__generated__/updateExpert";
import { getExpertForUpdate_getExpert } from "../../graphql/__generated__/getExpertForUpdate";
import { GET_EXPERT_METHODS_SCOPES_SKILLS } from "../../graphql/GET_EXPERT_METHODS_SCOPES_SKILLS";

export const useCreateUpdateExpertForm = (
  expertDataForUpdate: getExpertForUpdate_getExpert | undefined
) => {
  const { setLoading, setNotificationMsg, setNotificationEvent } =
    useContext(globalDataCTX);
  const [minSessionDuration, setMinSessionDuration] = useState("7");
  const [priorityCatalog, setPriorityCatalog] = useState("0");

  const [expertDisplayStatus, setExpertDisplayStatus] =
    useState<ExpertDisplayStatus>(
      expertDataForUpdate?.displayStatus || ExpertDisplayStatus.visible_false
    );
  const [expertActivityStatus, setExpertActivityStatus] = useState<
    ExpertActivityStatus | any | null // Использую any, потому что на бэке тип данных неправильный, но обещали исправить.
  >(null);

  useEffect(() => {
    if (expertDataForUpdate) {
      setMinSessionDuration(
        expertDataForUpdate?.minSessionDuration?.toString()
      );
      setExpertDisplayStatus(expertDataForUpdate?.displayStatus!);
      setExpertActivityStatus(expertDataForUpdate?.activityStatus);
      setPriorityCatalog(expertDataForUpdate?.catalogPriority?.toString() || "0")
    }
  }, [expertDataForUpdate]);

  const [
    createExpertHandler,
    {
      data: dataCreate,
      loading: loadingCreateExpert,
      error: errorCreateExpert,
    },
  ] = useMutation<addExpert, addExpertVariables>(CREATE_EXPERT);

  const [
    updateExpertHandler,
    {
      data: dataUpdate,
      loading: loadingUpdateExpert,
      error: errorUpdateExpert,
    },
  ] = useMutation<updateExpert, updateExpertVariables>(UPDATE_EXPERT);

  const { data: detailsExperts } = useQuery(GET_EXPERT_METHODS_SCOPES_SKILLS, { fetchPolicy: "network-only" });

  useEffect(() => {
    setLoading(loadingCreateExpert || loadingUpdateExpert);
    if (errorCreateExpert || errorUpdateExpert) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        errorCreateExpert?.message || errorUpdateExpert?.message || ""
      );
    } else if (dataUpdate || dataCreate) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успех");
    } else {
      setNotificationEvent(notificationEventType.noEvent);
    }
  }, [
    loadingCreateExpert,
    loadingUpdateExpert,
    errorCreateExpert,
    errorUpdateExpert,
    setLoading,
    dataUpdate,
    dataCreate,
    setNotificationEvent,
    setNotificationMsg,
  ]);

  const submitHandler = (values: any) => {
    if (expertDataForUpdate) {
      updateExpertHandler({
        variables: {
          input: values,
          updateNewInput: {
            minSessionDuration:
              expertDataForUpdate?.minSessionDuration ===
                Number(minSessionDuration)
                ? null
                : Number(minSessionDuration),
            catalogPriority: priorityCatalog ? Number(priorityCatalog) : 0,
            displayStatus:
              expertDataForUpdate?.displayStatus === expertDisplayStatus
                ? null
                : expertDisplayStatus,
            activityStatus:
              expertDataForUpdate?.activityStatus === expertActivityStatus
                ? null
                : expertActivityStatus,
            id: expertDataForUpdate.id,
          },
        },
      });
    } else {
      createExpertHandler({
        variables: {
          input: {
            ...values,
            minSessionDuration: Number(minSessionDuration),
            catalogPriority: priorityCatalog ? Number(priorityCatalog) : null,
          },
        },
      });
    }
  };

  const initialValues = useMemo(() => {
    if (expertDataForUpdate) {
      return {
        name: expertDataForUpdate?.name || "",
        description: expertDataForUpdate?.description || "",
        gender: expertDataForUpdate
          ? expertDataForUpdate.gender
          : Gender.FEMALE || Gender.MALE,
        title: expertDataForUpdate?.title || "",
        careerStartDate: expertDataForUpdate?.careerStartDate || "",
        chatRate: expertDataForUpdate?.rates.chat || 0,
        videoCallRate: expertDataForUpdate?.rates.videoCall || 0,
        phoneCallRate: expertDataForUpdate?.rates.phoneCall || 0,
        scopes: expertDataForUpdate?.scopes
          ? idFiltering(expertDataForUpdate?.scopes)
          : [],
        skills: expertDataForUpdate?.skills
          ? idFiltering(expertDataForUpdate?.skills)
          : [],
        methods: expertDataForUpdate?.methods
          ? idFiltering(expertDataForUpdate?.methods)
          : [],
        id: `${expertDataForUpdate?.id}`,
        tg: expertDataForUpdate?.tg,
      };
    }

    return {
      email: "",
      password: "",
      name: "",
      description: "",
      gender: Gender.FEMALE || Gender.MALE,
      title: "",
      careerStartDate: "",
      chatRate: 0,
      videoCallRate: 0,
      phoneCallRate: 0,
      scopes: [],
      skills: [],
      methods: [],
      tg: "",
    };
  }, [expertDataForUpdate]);

  return {
    initialValues,
    detailsExperts,
    trialAllowance: expertDataForUpdate?.trialAllowance,
    minSessionDuration,
    expertDisplayStatus,
    expertActivityStatus,
    priorityCatalog,
    setPriorityCatalog,
    setExpertActivityStatus,
    setExpertDisplayStatus,
    submitHandler,
    setMinSessionDuration,
  };
};
