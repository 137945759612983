import SelectCheckBox from "./components/selectCheckBox";
import SingleDataPicker from "./components/singleDataPicker";
import GenderDateSelect from "./components/genderDateSelect";
import FormButtonsPanel from "./components/formButtonsPanel";
import HtmlTextEditor from "./components/htmlTextEditor";
import UploadFilesForExperts from "./components/uploadFilesForExperts";
import IconWithTextLabel from "./components/iconWithTextLabel";
import { ErrorMessage, Form, Formik } from "formik";
import { validationSchema, validationSchemaUpdate } from "./validationSchema";
import { CreateUpdateExpertFormProps } from "./types";
import { Box, TextField } from "@mui/material";
import { faRubleSign } from "@fortawesome/free-solid-svg-icons";
import { StyledBlockInput } from "./styles";
import ExpertTrialSelectCheckmarks from "./components/trialComponent";
import {
  StyledErrorText,
  HeaderTitle,
  StyledDivider,
} from "../../../../../styles/common/styles";
import { useCreateUpdateExpertForm } from "./useCreateUpdateExpertForm";
import ExpertDisplayStatusSelect from "./components/expertDisplayStatusSelect";
import ExpertActivityStatusSelect from "./components/expertActivityStatusSelect";

const CreateUpdateExpertForm = ({
  expertDataForUpdate,
}: CreateUpdateExpertFormProps) => {
  const {
    initialValues,
    detailsExperts,
    trialAllowance,
    submitHandler,
    setMinSessionDuration,
    minSessionDuration,
    expertDisplayStatus,
    expertActivityStatus,
    setExpertActivityStatus,
    setExpertDisplayStatus,
    priorityCatalog,
    setPriorityCatalog
  } = useCreateUpdateExpertForm(expertDataForUpdate);

  const currentFilesForExpert = {
    coverImages: expertDataForUpdate?.cover,
    avatar: expertDataForUpdate?.image,
    audio: expertDataForUpdate?.audioGreetingUrl
  }

  return (
    <>
      <Box mb={6}>
        <HeaderTitle>
          {expertDataForUpdate
            ? "Обновление эксперта"
            : "Создание нового эксперта"}
        </HeaderTitle>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitHandler(values)}
        validationSchema={
          expertDataForUpdate ? validationSchemaUpdate : validationSchema
        }
        enableReinitialize={true}
      >
        {({
          handleBlur,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                gap: 2,
                alignItems: "center",
              }}
            >
              {!expertDataForUpdate && (
                <>
                  <StyledBlockInput>
                    <TextField
                      onBlur={handleBlur}
                      name="email"
                      value={values?.email}
                      label="Эл. почта"
                      onChange={handleChange}
                      fullWidth
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                    />
                  </StyledBlockInput>
                  <StyledBlockInput>
                    <TextField
                      onBlur={handleBlur}
                      name="password"
                      value={values.password}
                      label="Пароль"
                      onChange={handleChange}
                      fullWidth
                    />
                    <ErrorMessage
                      name="password"
                      render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                    />
                  </StyledBlockInput>
                </>
              )}
              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="name"
                  value={values.name}
                  label="Имя"
                  onChange={handleChange}
                  fullWidth
                />
                <ErrorMessage
                  name="name"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <SelectCheckBox
                  checkList={detailsExperts?.getScopes}
                  setFieldValue={setFieldValue}
                  valuesKey="scopes"
                  placeholder="Сферы"
                  initialValue={values.scopes!}
                />
                <ErrorMessage
                  name="scopes"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="title"
                  value={values.title}
                  label="Заголовок"
                  onChange={handleChange}
                  fullWidth
                />
                <ErrorMessage
                  name="title"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <GenderDateSelect
                  initialValue={values.gender}
                  setFieldValue={setFieldValue}
                  valuesKey="gender"
                />
                <ErrorMessage
                  name="gender"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <SingleDataPicker
                  setFieldValue={setFieldValue}
                  values={values.careerStartDate}
                  valuesKey="careerStartDate"
                />
                <ErrorMessage
                  name="careerStartDate"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <SelectCheckBox
                  checkList={detailsExperts?.getSkills}
                  setFieldValue={setFieldValue}
                  valuesKey="skills"
                  placeholder="Навыки"
                  initialValue={values.skills!}
                />
                <ErrorMessage
                  name="skills"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="chatRate"
                  value={values.chatRate}
                  label={
                    <IconWithTextLabel text="Тариф чата" icon={faRubleSign} />
                  }
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
                <ErrorMessage
                  name="chatRate"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="videoCallRate"
                  value={values?.videoCallRate}
                  label={
                    <IconWithTextLabel
                      text="Тариф видео звонка"
                      icon={faRubleSign}
                    />
                  }
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
                <ErrorMessage
                  name="videoCallRate"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="phoneCallRate"
                  value={values?.phoneCallRate}
                  label={
                    <IconWithTextLabel
                      text=" Тариф тел. звонка"
                      icon={faRubleSign}
                    />
                  }
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
                <ErrorMessage
                  name="phoneCallRate"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <SelectCheckBox
                  checkList={detailsExperts?.getMethods}
                  setFieldValue={setFieldValue}
                  valuesKey="methods"
                  placeholder="Методы"
                  initialValue={values.methods!}
                />
                <ErrorMessage
                  name="methods"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="tg"
                  value={values?.tg}
                  label="Телеграм эксперта"
                  onChange={handleChange}
                  fullWidth
                />
                <ErrorMessage
                  name="tg"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <ExpertDisplayStatusSelect
                  initialValue={expertDisplayStatus}
                  setExpertDisplayStatus={setExpertDisplayStatus}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <ExpertActivityStatusSelect
                  initialValue={expertActivityStatus}
                  setExpertActivityStatus={setExpertActivityStatus}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <TextField
                  name="minSessionDuration"
                  value={minSessionDuration}
                  label="мин. продолжительность сеанса"
                  onChange={(e) => setMinSessionDuration(e.target.value)}
                  fullWidth
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <TextField
                  name="catalogPriority"
                  value={priorityCatalog}
                  label="Приоритет в каталоге"
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ''); // Удаляем все нецифровые символы
                    setPriorityCatalog(value);
                  }}
                  fullWidth
                />
              </StyledBlockInput>
            </Box>

            <HtmlTextEditor
              value={values.description}
              setFieldValue={setFieldValue}
              valuesKey={"description"}
            />
            <ErrorMessage
              name="description"
              render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
            />

            <FormButtonsPanel isUpdateForm={!!expertDataForUpdate} />
            <Box mt={30} mb={20}>
              {expertDataForUpdate && (
                <>
                  <StyledDivider>Изменить триал</StyledDivider>
                  <ExpertTrialSelectCheckmarks
                    trialAllowance={trialAllowance}
                    expertId={expertDataForUpdate.id}
                  />

                  <UploadFilesForExperts
                    currentFilesForExpert={currentFilesForExpert}
                    expertId={expertDataForUpdate?.id!}
                    userId={expertDataForUpdate?.userID!}
                    uuid={expertDataForUpdate?.uuid}
                  />
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateUpdateExpertForm;
